import styled, { keyframes, css } from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg);
  }
`;
export const Container = styled.div`
  z-index: 5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  ${css`
    svg {
      animation: ${rotate} 2s linear infinite;
    }
  `}
  footer {
    margin-top: 15px;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height: 600px;
`;
