import React from 'react';
import PropTypes from 'prop-types';

import Header from '~/components/Header';
import { Wrapper } from './styles';
import { useProviderHook } from '~/hooks/provider';

export default function DefaulLayout({ children }) {
  const { getBilletToken } = useProviderHook();
  const billetToken = getBilletToken();

  return (
    <Wrapper>
      {!billetToken && <Header />}
      {children}
    </Wrapper>
  );
}

DefaulLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
