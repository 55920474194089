export function updateProfileRequest(data) {
  return {
    type: '@beneficiarie/UPDATE_PROFILE_REQUEST',
    payload: { data },
  };
}
export function updateProfileSuccess(profile) {
  return {
    type: '@beneficiarie/UPDATE_PROFILE_SUCCESS',
    payload: { profile },
  };
}
export function updateProfileFailure() {
  return {
    type: '@beneficiarie/UPDATE_PROFILE_FAILURE',
  };
}

export function createBeneficiarieRequest(data) {
  return {
    type: '@beneficiarie/CREATE_BENEFICIARIE_REQUEST',
    payload: { data },
  };
}
export function createBeneficiarieSuccess(beneficiarie) {
  return {
    type: '@beneficiarie/CREATE_BENEFICIARIE_SUCCESS',
    payload: { beneficiarie },
  };
}
export function createBeneficiarieFailure() {
  return {
    type: '@beneficiarie/CREATE_BENEFICIARIE_FAILURE',
  };
}
