import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 740px;
  max-width: 740px;
  justify-content: space-between;
  flex-wrap: wrap;
  > div#banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  @media (max-width: 398px) {
    justify-content: center;
  }
`;
export const Menu = styled.div`
  text-align: center;
  a,
  button {
    display: flex;
    width: 193px;
    height: 90px;
    border: none;
    background: ${props => (props.disable ? '#999' : '#f47920')};
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    cursor: ${props => (props.disable ? 'not-allowed' : 'pointer')};
    padding: 20px;
    margin-top: 40px;

    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background: ${props =>
        props.disable ? '#999' : darken(0.03, '#f47920')};
    }
    span {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }
  }
`;
