import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from './provider';

const AppProvider = ({ children }) => <Provider>{children}</Provider>;

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default AppProvider;
