import React from 'react';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@rocketseat/unform';
import { FaSpinner } from 'react-icons/fa';
import { signUpRequest } from '~/store/modules/auth/actions';

import Input from '~/components/Input';
import logo from '~/assets/images/logo.png';

const schema = Yup.object().shape({
  cpf: Yup.string().required('O cpf é obrigatório'),
  password: Yup.string()
    .min(6, 'A senha deve conter no mínimo 6 caracteres')
    .required('O campo senha é obrigatório'),
  card: Yup.string().required('A carteirinha é obrigatória'),
  email: Yup.string()
    .email('Informe um email válido')
    .required('O email é obrigatório'),
});

export default function SignUp() {
  const loading = useSelector(state => state.auth.loading);
  const dispatch = useDispatch();
  function handleSubmit(data) {
    const cpf = data.cpf.replace(/[^0-9]/g, '');
    const beneficiarie = {
      ...data,
      cpf,
    };

    dispatch(signUpRequest(beneficiarie));
  }
  return (
    <>
      <img src={logo} alt="Agenda Unimed" />
      <Form onSubmit={handleSubmit} schema={schema}>
        <Input
          mask="999.999.999-99"
          name="cpf"
          type="text"
          label="CPF"
          placeholder="Seu cpf"
        />
        <Input
          mask="9999999999999"
          label="Nº Carteirinha (Sem 0075)"
          name="card"
          type="text"
          placeholder="Número da sua carteirinha"
        />
        <Input
          name="email"
          label="Email"
          type="email"
          placeholder="Informe seu melhor email"
        />
        <Input
          name="password"
          label="Senha"
          type="password"
          placeholder="Sua senha"
        />
        <button id="default" type="submit">
          {loading ? <FaSpinner color="#fff" size={22} /> : 'Cadastrar'}
        </button>
        <Link to="/">Já tenho acesso</Link>
      </Form>
    </>
  );
}
