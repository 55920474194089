import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import * as Yup from 'yup';

import { Form } from '@rocketseat/unform';
import Input from '~/components/Input';
import logo from '~/assets/images/logo.png';

import { signInRequest } from '~/store/modules/auth/actions';

const schema = Yup.object().shape({
  cpf: Yup.string().required('O CPF é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const loading = useSelector(state => state.auth.loading);
  const dispatch = useDispatch();
  function handleSubmit(data) {
    const cpf = data.cpf.replace(/[^0-9]/g, '');
    const { password } = data;

    cpf.replace(/[^0-9]/g, '');

    dispatch(signInRequest(cpf, password));
  }
  return (
    <>
      <img src={logo} alt="Unimed Três Lagoas" />
      <Form onSubmit={handleSubmit} schema={schema}>
        <Input
          mask="999.999.999-99"
          name="cpf"
          label="CPF"
          type="text"
          placeholder="Seu cpf"
        />
        <Input
          label="Senha"
          name="password"
          type="password"
          placeholder="Sua senha"
        />
        <button id="default" type="submit">
          {loading ? <FaSpinner color="#fff" size={22} /> : 'Acessar'}
        </button>
        <Link to="/cadastrar">Cadastre-se aqui</Link>
        <Link to="/recuperar-senha">Esqueci minha senha.</Link>
      </Form>
    </>
  );
}
