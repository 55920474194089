import React, { useEffect, useState, useCallback } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
// import { MdClose, MdSend } from 'react-icons/md';
// import { Form } from '@rocketseat/unform';
// import * as Yup from 'yup';
// import { confirmAlert } from 'react-confirm-alert';

import {
  Container,
  Content,
  Title,
  Billets,
  BilletContent,
  BilletView,
  Button,
  Buttons,
} from './styles';

import api from '~/services/api';

import Modal from '~/components/Modal';
// import ModalEmail from '~/components/DefaultModal';
// import Input from '~/components/Input';

import { formatter } from '~/utils/formatCurrency';
import { useProviderHook } from '~/hooks/provider';

// const schema = Yup.object().shape({
//   mail: Yup.string()
//     .email('Informe um email válido')
//     .required('Esse campo é de preenchimento obrigatório'),
// });

export default function Billet() {
  const { getBilletToken } = useProviderHook();
  const billetToken = getBilletToken();

  const [billets, setBillets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [billetGenerated, setBilletGenerated] = useState();
  const [billetPrint, setBilletPrint] = useState('');

  const handleGenerate = useCallback(
    async billet => {
      try {
        setModalOpen(true);

        const response = await api.post(
          `/beneficiaries/billets?token=${billetToken}`,
          {
            tituloId: billet.tituloId,
          },
          {
            responseType: 'arraybuffer',
          }
        );

        const file = new Blob([response.data], {
          type: 'application/pdf',
        });
        const fileURL = URL.createObjectURL(file);
        // if (isSafari) {
        //   window.location.assign(
        //     fileURL,
        //     '_blank',
        //     'toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=500,width=683,height=600'
        //   );
        // } else {
        //   window.open(
        //     fileURL,
        //     '_blank',
        //     'toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=500,width=683,height=600'
        //   );
        // }

        setBilletPrint(fileURL);
        setModalOpen(false);
        setBilletGenerated(billet);
      } catch (error) {
        toast.error(
          'Ocorreu um erro ao tentar gerar o boleto, por favor entre em contato com o suporte!'
        );
        setModalOpen(false);
        // setModalEmailOpen(true);
      }
    },
    [billetToken]
  );

  const handleCopyDigitableLine = async () => {
    try {
      const html = await api.post(
        `/beneficiaries/billets?token=${billetToken}`,
        {
          tituloId: billetGenerated.tituloId,
        },
        {
          params: {
            getHtml: true,
          },
        }
      );
      const parser = new DOMParser();
      const doc = parser.parseFromString(html.data, 'text/html');
      const linhaDigitavel = doc.getElementById('linha-digitavel-2').innerHTML;

      navigator.clipboard.writeText(linhaDigitavel);
      toast.info('Linha digitável copiada!');
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao tentar copiar a linha digitável, tente novamente!'
      );
    }
  };
  // ENVIO DE EMAIL PARA O BENEFICIÁRIO COM O BOLETO
  // async function handleSubmit(data) {
  //   setLoading(true);
  //   await api.post('mail/billet', {
  //     data: {
  //       name: billetPrint.NOME,
  //       cpf: billetPrint.CPF,
  //       email: data.mail,
  //       date: billetPrint.formattedDate,
  //       value: billetPrint.formattedValue,
  //       nosso_numero: billetPrint.NOSSO_NR,
  //     },
  //   });
  //   setLoading(false);
  //   setModalEmailOpen(false);
  //   confirmAlert({
  //     title: 'Envio de boleto',
  //     message: `O boleto com vencimento em ${billetPrint.formattedDate} no valor de ${billetPrint.formattedValue}, será enviado em até 15 minutos, dentro do horário comercial (SEG - SEX das 07:00 às 17:00).`,
  //     buttons: [
  //       {
  //         label: 'OK',
  //       },
  //     ],
  //     childrenElement: () => <div />,
  //     closeOnEscape: true,
  //     closeOnClickOutside: false,
  //     willUnmount: () => {},
  //     onClickOutside: () => {},
  //     onKeypressEscape: () => {},
  //   });
  // }

  useEffect(() => {
    async function loadBillets() {
      setLoading(true);
      try {
        const response = await api.get('/beneficiaries/billets');
        const data = response.data.titulos.map(titulo => ({
          ...titulo,
          formattedDate: titulo.dtVencimento,
          formattedValue: formatter(titulo.valor),
        }));
        setBillets(data);
        setLoading(false);
      } catch (error) {
        toast.warn('Não há nenhum boleto em aberto!');
        setLoading(false);
      }
    }

    async function loadBillet() {
      setLoading(true);
      try {
        const response = await api.get(
          `/beneficiaries/billets?token=${billetToken}`
        );
        await handleGenerate(response.data);
        setLoading(false);
      } catch (error) {
        toast.warn('Ocorreu um erro ao exibir o boleto!');
        setLoading(false);
      }
    }

    if (billetToken) {
      loadBillet();
    } else {
      loadBillets();
    }
  }, [billetToken, handleGenerate]);

  function handlePdfDownload() {
    const element = document.createElement('a');
    element.setAttribute('href', billetPrint);
    element.setAttribute('download', '2ª Via de Boleto.pdf');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
  }

  return (
    <Container>
      <Content>
        {!billetPrint && <Title>Emissão de Boletos</Title>}
        <Billets loading={loading}>
          {loading && (
            <div id="animation">
              <FaSpinner size={32} color="#f47920" />
            </div>
          )}
          {!loading &&
            !billetPrint &&
            billets.map(
              billet =>
                !!billet.exibirBotaoSegundaVia && (
                  <BilletContent key={billet.tituloId}>
                    <strong>{billet.formattedDate}</strong>
                    <strong>{billet.formattedValue}</strong>
                    <button
                      type="button"
                      onClick={() => handleGenerate(billet)}
                    >
                      GERAR BOLETO
                    </button>
                  </BilletContent>
                )
            )}
        </Billets>
      </Content>
      {billetPrint && (
        <BilletView>
          <Buttons>
            <Button type="button" onClick={handlePdfDownload}>
              Baixar PDF
            </Button>
            <Button type="button" onClick={handleCopyDigitableLine}>
              Copiar linha digitável
            </Button>
            {!billetToken && (
              <Button type="button" onClick={() => setBilletPrint('')}>
                Voltar
              </Button>
            )}
          </Buttons>
          <div id="frame">
            <iframe src={billetPrint} title="Boleto Unimed" />
          </div>
        </BilletView>
      )}

      {modalOpen && (
        <Modal>
          <FaSpinner size={42} color="#f47920" />
          <footer>Gerando boleto...</footer>
        </Modal>
      )}

      {/* {modalEmailOpen && (
        <ModalEmail>
          <header>
            <div id="title">
              <h1>
                Preencha as informações e enviaremos seu boleto o mais breve
                possível!
              </h1>
            </div>
          </header>
          <div>
            <Form schema={schema} onSubmit={handleSubmit}>
              <Input
                name="mail"
                label="E-mail"
                placeholder="Informe seu melhor email"
                type="email"
              />

              <footer>
                <button
                  id="delete"
                  type="button"
                  onClick={() => setModalEmailOpen(!modalEmailOpen)}
                >
                  Fechar <MdClose size={22} color="#fff" />
                </button>
                <button id="default" type="submit">
                  Enviar <MdSend size={22} color="#fff" />
                </button>
              </footer>
            </Form>
          </div>
        </ModalEmail>
      )} */}
    </Container>
  );
}
