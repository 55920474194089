import styled, { css, keyframes } from 'styled-components';
import { darken } from 'polished';

const rotate = keyframes` 
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg);
  }
`;
export const Container = styled.div`
  margin: 50px 50px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 18px;
    @media (max-width: 490px) {
      flex-direction: column;
      > button {
        margin-bottom: 20px;
      }
    }
    > button {
      display: flex;
      align-items: center;
      padding: 5px;
      justify-content: center;
      height: 44px;
      background: #f47920;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background(0.2s);

      &:hover {
        background: ${darken(0.03, '#f47920')};
      }

      ${props =>
        props.loading &&
        css`
          svg {
            animation: ${rotate} 2s linear infinite;
          }
        `}

      >svg {
        margin-left: 5px;
      }
    }
  }
`;

export const Top = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 10px;
  border-bottom: 2px solid #999;
  > div {
    font-size: 18px;
    font-weight: bold;
    color: #00995d;
  }
`;

export const ListService = styled.ul``;

export const Service = styled.li`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #999;
  padding-bottom: 15px;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > strong {
      font-size: 20px;
    }
    span {
      color: #999;
      text-align: right;
      font-size: 16px;
    }
  }
  div#service {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > strong {
      font-size: 12px;
      color: #666;
      margin-left: 15px;
    }
  }

  padding-bottom: 5px;
  border-bottom: 1px solid rgba(1, 1, 1, 0.2);
  &:last-child {
    border-bottom: 0;
  }
`;
