import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from '@rocketseat/unform';
import { format, subYears } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import * as Yup from 'yup';
import { isSafari } from 'react-device-detect';
import { confirmAlert } from 'react-confirm-alert';

import {
  MdInsertChart,
  MdPlaylistAddCheck,
  MdBlock,
  MdDescription,
  MdAutorenew,
  MdAttachMoney,
  MdPrint,
  MdClose,
  MdSend,
} from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';

import api from '~/services/api';

import DefaultModal from '~/components/DefaultModal';
import Banner from '~/components/Banner';
import Select from '~/components/ReactSelect';
import Input from '~/components/Input';
import DatePicker from '~/components/ReactDatePicker';

import { Container, Content, Menu } from './styles';

const year = format(subYears(new Date(), 1), 'yyyy');
const schema = Yup.object().shape({
  contract: Yup.string().required('Favor selecionar o contrato'),
  year: Yup.number()
    .min(1900, 'Informe um ano superior a 1899')
    .max(year, `Informe um ano igual ou inferior a ${year}`)
    .typeError('É necessário informar o ano'),
});

const schemaInvoice = Yup.object().shape({
  period: Yup.date().required('Favor selecionar o período'),
  mail: Yup.string()
    .email('Informe um email válido')
    .required('O email é obrigatório'),
});

export default function Dashboard() {
  const [modalIROpen, setModalIROpen] = useState(false);
  const [modalEmailOpen, setModalEmailOpen] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadBanner() {
      setLoading(true);
      try {
        const response = await api.get('/beneficiaries/banners');
        setBanners(response.data);
      } catch (error) {
        console.tron.log(error);
      }
      setLoading(false);
    }

    loadBanner();
  }, []);

  async function handleIRGenerate() {
    const response = await api.get('beneficiaries/ir');
    if (response.data.tit.length === 0) {
      toast.error('Não foram encontrados registros!');
    } else {
      const data = response.data.tit;
      setContracts(
        data.map(c => ({
          title: `${c.CONTRATO} - ${c.STATUS}`,
          id: c.CONTRATO,
        }))
      );
      setModalIROpen(!modalIROpen);
    }

    // setModalIROpen(!modalIROpen);
  }

  async function handleSendMailToDetailInvoice(data) {
    try {
      const { mail } = data;
      const period = format(data.period, 'MM/yyyy');
      await api.post('mail/invoice', {
        data: {
          // TODO remover isso para ajustar no backend
          mail,
          period,
        },
      });
      setModalEmailOpen(false);
      confirmAlert({
        title: 'Detalhamento de Fatura',
        message: `Sua solicitação do detalhamento de fatura referente ao período ${period} foi solicitado, e será enviado para seu email em até 7 dias úteis.`,
        buttons: [
          {
            label: 'OK',
          },
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: false,
        willUnmount: () => {},
        onClickOutside: () => {},
        onKeypressEscape: () => {},
      });
    } catch (error) {
      toast.error(
        'Houve um erro ao tentar processar sua solicitação, tente novamente mais tarde.'
      );
    }
  }

  async function handleSubmit(data) {
    try {
      const response = await api.post(
        '/beneficiaries/ir',
        {
          contract: data.contract,
          year: data.year,
        },
        {
          responseType: 'arraybuffer',
        }
      );
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      if (isSafari) {
        window.location.assign(fileURL, '_blank');
      } else {
        window.open(fileURL, '_blank');
      }
      setModalIROpen(false);
    } catch (error) {
      toast.error('Nenhum dado encontrado para geração do relatório!');
    }
  }

  return (
    <Container>
      <Content>
        <div id="banner">
          {loading ? (
            <FaSpinner size={22} color="#f47920" />
          ) : (
            <Banner banners={banners} />
          )}
        </div>
        <Menu>
          <Link to="/boletos">
            <MdInsertChart color="#fff" size={60} />
            <span>Segunda via de Boleto</span>
          </Link>
        </Menu>
        <Menu>
          <button type="button" onClick={handleIRGenerate}>
            <MdAttachMoney color="#fff" size={60} />
            <span>Imposto de Renda</span>
          </button>
        </Menu>
        <Menu disable>
          <Link>
            <MdPlaylistAddCheck color="#fff" size={60} />
            <span>Histórico de Atendimentos</span>
          </Link>
        </Menu>
        <Menu disable>
          <Link>
            <MdBlock color="#fff" size={60} />
            <span>Cancelamento de Contrato</span>
          </Link>
        </Menu>
        <Menu>
          <button type="button" onClick={() => setModalEmailOpen(true)}>
            <MdDescription color="#fff" size={60} />
            <span>Detalhamento de Fatura</span>
          </button>
        </Menu>
        <Menu disable>
          <Link>
            <MdAutorenew color="#fff" size={60} />
            <span>Atualização Cadastral</span>
          </Link>
        </Menu>
      </Content>

      {modalIROpen && (
        <DefaultModal>
          <header>
            <div id="title">
              <h1>Preencha as informações para gerar o relatório</h1>
            </div>
          </header>
          <div>
            <Form schema={schema} onSubmit={handleSubmit}>
              <Select
                name="contract"
                label="Selecione o contrato"
                placeholder="Selecione..."
                options={contracts}
                isClearable
              />

              <Input
                name="year"
                label="Ano"
                placeholder="Informe o ano de referencia..."
                mask="9999"
                type="value"
              />

              <footer>
                <button
                  id="delete"
                  type="button"
                  onClick={() => setModalIROpen(!modalIROpen)}
                >
                  Fechar <MdClose size={22} color="#fff" />
                </button>
                <button id="default" type="submit">
                  Gerar <MdPrint size={22} color="#fff" />
                </button>
              </footer>
            </Form>
          </div>
        </DefaultModal>
      )}

      {modalEmailOpen && (
        <DefaultModal>
          <header>
            <div id="title">
              <h1>
                Preencha as informações e enviaremos seu detalhamento de fatura
                o mais breve possível!
              </h1>
            </div>
          </header>
          <div>
            <Form
              schema={schemaInvoice}
              onSubmit={handleSendMailToDetailInvoice}
            >
              <DatePicker
                mask="99/9999"
                label="Mês de referência"
                name="period"
                placeholder="Informe o mês que deseja o detalhamento"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                locale={pt}
              />
              <Input
                name="mail"
                label="E-mail"
                placeholder="Informe seu melhor email"
                type="email"
              />

              <footer>
                <button
                  id="delete"
                  type="button"
                  onClick={() => setModalEmailOpen(!modalEmailOpen)}
                >
                  Fechar <MdClose size={22} color="#fff" />
                </button>
                <button id="default" type="submit">
                  Enviar <MdSend size={22} color="#fff" />
                </button>
              </footer>
            </Form>
          </div>
        </DefaultModal>
      )}
    </Container>
  );
}
