import styled from 'styled-components';
import Slider from 'react-slick';

export const Container = styled.div`
  display: flex;
`;

export const BannerUnimed = styled(Slider)`
  width: 700px;
  height: 300px;
  @media (max-width: 765px) {
    width: 600px;
    height: 300px;
  }
  @media (max-width: 665px) {
    width: 550px;
    height: 250px;
  }
  @media (max-width: 610px) {
    width: 500px;
    height: 250px;
  }
  @media (max-width: 555px) {
    width: 450px;
    height: 200px;
  }
  @media (max-width: 510px) {
    width: 400px;
    height: 200px;
  }
  @media (max-width: 450px) {
    width: 350px;
    height: 150px;
  }
  @media (max-width: 398px) {
    width: 300px;
    height: 150px;
  }
`;

export const ListImage = styled.div`
  align-items: center;

  img {
    width: 700px;
    height: 300px;
    @media (max-width: 765px) {
      width: 600px;
      height: 300px;
    }
    @media (max-width: 665px) {
      width: 550px;
      height: 250px;
    }
    @media (max-width: 610px) {
      width: 500px;
      height: 250px;
    }
    @media (max-width: 555px) {
      width: 450px;
      height: 200px;
    }
    @media (max-width: 510px) {
      width: 400px;
      height: 200px;
    }
    @media (max-width: 450px) {
      width: 350px;
      height: 150px;
    }
    @media (max-width: 398px) {
      width: 300px;
      height: 150px;
    }
  }
`;
