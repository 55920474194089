import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Container, Content, Profile, Menu } from './styles';

import logo from '~/assets/images/logo.png';

import { signOut } from '~/store/modules/auth/actions';

export default function Header() {
  const profile = useSelector(state => state.beneficiarie.profile);
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <Content>
        <nav>
          <Link to="/">
            <img src={logo} alt="Unimed Três Lagoas" />
          </Link>
        </nav>

        <Menu>
          <Link to="/dashboard">
            <li>Início</li>
          </Link>
        </Menu>

        <aside>
          <Profile>
            <div>
              <strong>{profile && profile.name} </strong>
              <div id="buttons">
                <Link to="/profile">Meu perfil</Link>
                <button type="button" id="danger" onClick={handleSignOut}>
                  Sair
                </button>
              </div>
            </div>
            <img
              src={
                profile &&
                (profile.avatar
                  ? profile.avatar.url
                  : `https://ui-avatars.com/api/?name=${profile.name}&color=00995d`)
              }
              alt={profile && profile.name}
            />
            {/* <MdArrowDropDown color="#fff" size={22} /> */}
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
