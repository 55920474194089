import styled, { keyframes, css } from 'styled-components';

import { darken } from 'polished';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg);
  }
`;
export const Container = styled.div`
  max-width: 600px;
  margin: 50px auto;

  form {
    display: flex;
    flex-direction: column;
    margin: 30px 30px 0 30px;

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #333;
      margin: 0 0 10px;

      &::placeholder {
        color: #333;
      }
    }

    div#checkbox {
      display: flex;
      align-items: center;
      height: 44px;
      max-height: 44px;
      > input {
        margin: 0;
        height: 100%;
        width: 25px;
        height: 25px;
      }
    }

    hr {
      border: 0;
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      margin: 10px 0 20px;
    }

    span {
      color: #ffcb08;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    label {
      color: #f47920;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
      line-height: 44px;
      margin-right: 15px;
    }

    button {
      margin: 5px 0 0;
      height: 44px;
      background: #f47920;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background(0.2s);

      ${props =>
        props.loading &&
        css`
          svg {
            animation: ${rotate} 2s linear infinite;
          }
        `}

      &:hover {
        background: ${darken(0.08, '#f47920')};
      }
    }

    button#cancel {
      margin: 5px 0 0;
      height: 44px;
      background: #dc3545;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background(0.2s);

      &:hover {
        background: ${darken(0.08, '#dc3545')};
      }
    }
  }
`;
