import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Form } from '@rocketseat/unform';
import { FaSpinner } from 'react-icons/fa';
import logo from '~/assets/images/logo.png';
import api from '~/services/api';
import history from '~/services/history';

import Input from '~/components/Input';

import { Container } from './styles';

const schema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'A senha deve conter no mínimo 6 caracteres')
    .required('A senha é obrigatória'),
  confirmPassword: Yup.string().when('password', (password, field) =>
    password
      ? field
          .required('A confirmação de senha é obrigatória')
          .oneOf([Yup.ref('password')], 'Confirmação de senha incorreta')
      : field
  ),
});

export default function ResetPassword({ location }) {
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState([]);
  const [tokenValid, setTokenValid] = useState(false);
  const [tokenParam, setTokenParam] = useState(undefined);
  // const { loading } = useSelector(state => state.beneficiarie);
  async function handleSubmit(data) {
    try {
      setLoading(true);
      await api.put('/beneficiaries/recovery', data, {
        params: {
          token: tokenParam,
        },
      });
      toast.success('Senha atualizada com sucesso!');
      setTimeout(() => {
        history.push('/');
      }, 3000);
      setLoading(false);
    } catch (error) {
      toast.error(
        'Não foi possível atualizar a senha, verifique se as duas coincidem!'
      );
      setLoading(false);
    }
  }

  useEffect(() => {
    async function verifyToken() {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      setLoadingInitial(true);
      try {
        const response = await api.get('/beneficiaries/recovery', {
          params: {
            token,
          },
        });
        if (response.status === 200) {
          setTokenValid(true);
          setTokenParam(token);
        }
        setLoadingInitial(false);
      } catch (error) {
        setLoadingInitial(false);
      }
    }

    verifyToken();
  }, []); //eslint-disable-line

  return (
    <Container loading={loading}>
      <img src={logo} alt="Agenda Unimed" />
      <Form onSubmit={handleSubmit} schema={schema}>
        {tokenValid && !loadingInitial ? (
          <>
            <Input
              name="password"
              type="password"
              label="Senha"
              placeholder="Informe uma senha com no mínimo 6 caracteres"
            />
            <Input
              name="confirmPassword"
              type="password"
              label="Confirmação de senha"
              placeholder="Confirme sua senha"
            />

            <button id="default" type="submit">
              {loading ? <FaSpinner size={24} color="#fff" /> : 'Resetar senha'}
            </button>
            <Link to="/">Voltar para o login</Link>
          </>
        ) : (
          !tokenValid &&
          !loadingInitial && (
            <>
              <span id="alert">
                Este link é inválido, por favor tente redefinir sua senha
                novamente!
              </span>
              <Link to="/">Voltar</Link>
            </>
          )
        )}
      </Form>
    </Container>
  );
}

ResetPassword.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};
