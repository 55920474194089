import styled, { keyframes, css } from 'styled-components';
import { darken } from 'polished';
import background from '~/assets/images/background.png';

export const Wrapper = styled.div`
  height: 100%;
  /* background: linear-gradient(-90deg, #00995d, #0a5f55); */
  background-image: url(${background});
  display: flex;
  justify-content: center;
  align-items: center;
`;
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg);
  }
`;
export const Content = styled.div`
  width: 100%;
  max-width: 400px;
  text-align: center;
  @media (max-width: 418px) {
    padding: 25px;
  }
  > h1 {
    padding-bottom: 40px;
    color: #f47920;
    font-size: 38px;
  }
  > div {
    background: linear-gradient(-90deg, #00995d, #0a5f55);
    padding-top: 50px;
    padding: 50px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    box-shadow: 5px 5px 2px #aaaaaa;
    img {
      width: 50%;
    }

    form {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      input {
        background: rgba(0, 0, 0, 0.1);
        border: 0;
        border-radius: 4px;
        height: 44px;
        padding: 0 15px;
        color: #fff;
        margin: 0 0 10px;

        &::placeholder {
          color: rgba(255, 255, 255, 0.7);
        }
      }

      span {
        color: #ffcb08;
        align-self: flex-start;
        margin: 0 0 10px;
        font-weight: bold;
      }

      button {
        margin: 5px 0 0;
        height: 44px;
        background: #f47920;
        font-weight: bold;
        color: #fff;
        border: 0;
        border-radius: 4px;
        font-size: 16px;
        transition: background(0.2s);

        &:hover {
          background: ${darken(0.03, '#f47920')};
        }

        ${props =>
          props.loading &&
          css`
            svg {
              animation: ${rotate} 2s linear infinite;
            }
          `}
      }

      a {
        color: #fff;
        margin: 10px 0;
        font-size: 16px;
        &:hover {
          color: #f47920;
        }
      }
    }
  }
`;
