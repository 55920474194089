import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { MdSearch } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import { subMonths, format, getDate, getMonth } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { toast } from 'react-toastify';
// import { isMobile, isSafari } from 'react-device-detect';

import { formatter } from '~/utils/formatCurrency';

import api from '~/services/api';

import { Container, Dates } from './styles';

import InvoiceDetailing from '~/components/InvoiceDetailing';
import ReactDatePicker from '~/components/ReactDatePicker';
// import ModalReport from '~/components/ModalReport';

const schema = Yup.object().shape({
  start_date: Yup.date().required('Por favor, informe o mês de inicio'),
  end_date: Yup.date().required('Por favor, informe o mês de fim'),
});

export default function InvoiceDetail() {
  const [historics, setHistorics] = useState([]);
  // const [modalOpen, setModalOpen] = useState(false);
  // const [pdfDocument, setPdfDocument] = useState(undefined);
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const initialData = {
    start_date: subMonths(new Date(), 1),
    end_date: subMonths(new Date(), 1),
  };

  async function handleGeneratePdf() {
    const start_date = format(subMonths(dates.start_date, 2), 'yyyyMM');
    const end_date = format(subMonths(dates.end_date, 2), 'yyyyMM');
    const response = await api.post(
      '/beneficiaries/invoice/report',
      {},
      {
        params: {
          start_date,
          end_date,
        },
        responseType: 'blob',
      }
    );

    const file = new Blob([response.data], {
      type: 'application/pdf',
    });
    const fileURL = URL.createObjectURL(file);
    const element = document.createElement('a');
    element.setAttribute('href', fileURL);
    element.setAttribute(
      'download',
      `Detalhamento de Fatura ${format(
        subMonths(dates.start_date, 2),
        'yyyyMM'
      )} - ${format(subMonths(dates.end_date, 2), 'yyyyMM')} .pdf`
    );
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    // document.body.removeChild(element);
    // if (isMobile) {
    //   if (isSafari) {
    //     window.location.assign(fileURL, '_blank');
    //   } else {
    //     window.open(fileURL, '_blank');
    //   }
    // } else {
    //   setPdfDocument(fileURL);
    //   setModalOpen(true);
    // }
  }

  // const toggleModal = () => {
  //   setModalOpen(!modalOpen);
  // };
  const handleSubmit = useCallback(async data => {
    const date = new Date();
    if (
      (getDate(date) < 15 &&
        (Number(format(data.start_date, 'MM')) > getMonth(date) + 1 ||
          Number(format(data.end_date, 'MM')) > getMonth(date) + 1)) ||
      Number(format(data.end_date, 'MM')) >= getMonth(date) + 3 ||
      Number(format(data.start_date, 'MM')) >= getMonth(date) + 3
    ) {
      toast.error('O periodo de busca ainda não está disponível');
      return;
    }
    const start_date = format(subMonths(data.start_date, 2), 'yyyyMM');
    const end_date = format(subMonths(data.end_date, 2), 'yyyyMM');
    if (
      getMonth(subMonths(data.start_date, 2)) + 1 >
      getMonth(subMonths(data.end_date, 2)) + 1
    ) {
      toast.error('O mês final não pode ser anterior ao inicial');
    } else {
      setDates(data);
      setLoading(true);
      const response = await api.get('/beneficiaries/invoice', {
        params: {
          start_date,
          end_date,
        },
      });
      const dataHistoric = response.data.map(r => ({
        ...r,
        valueFormatted: formatter(r.VALOR_SERVICO),
      }));
      setHistorics(dataHistoric);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleSubmit(initialData);
  }, []); //eslint-disable-line

  return (
    <Container loading={loading}>
      <Dates>
        <Form onSubmit={handleSubmit} schema={schema} initialData={initialData}>
          <ReactDatePicker
            name="start_date"
            mask="99/9999"
            dateFormat="MM/yyyy"
            label="Mês Inicial"
            showMonthYearPicker
            locale={pt}
          />
          <ReactDatePicker
            name="end_date"
            mask="99/9999"
            dateFormat="MM/yyyy"
            label="Mês Final"
            showMonthYearPicker
            locale={pt}
          />
          <button type="submit" id="submit">
            <MdSearch color="#fff" size={18} />
          </button>
        </Form>
      </Dates>
      {loading ? (
        <div id="animation">
          <FaSpinner size={32} color="#f47920" />
        </div>
      ) : (
        <InvoiceDetailing
          historics={historics}
          handleGeneratePdf={handleGeneratePdf}
        />
      )}
      {/* {modalOpen && pdfDocument !== undefined && (
        <ModalReport size="big" handleModal={toggleModal}>
          <div id="button">
            <button type="button" id="close" onClick={toggleModal}>
              <FaWindowClose size={30} />
            </button>
          </div>
          <iframe src={pdfDocument} title="Histórico de atendimentos" />
        </ModalReport>
      )} */}
    </Container>
  );
}
