import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Billet from '~/pages/Billet';
import RecoveryPassword from '~/pages/RecoveryPassword';
import ResetPassword from '~/pages/ResetPassword';
// import Historic from '~/pages/Historic';
import InvoiceDetail from '~/pages/InvoiceDetail';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/cadastrar" component={SignUp} />
      <Route path="/recuperar-senha" component={RecoveryPassword} />
      <Route path="/reset-senha" component={ResetPassword} />
      <Route path="/profile" isPrivate component={Profile} />
      <Route path="/dashboard" component={Dashboard} isPrivate />

      <Route path="/boletos" component={Billet} isPrivate />
      <Route path="/segundaVia" component={Billet} />
      {/* <Route path="/historico" component={Historic} isPrivate /> */}
      <Route path="/faturas" component={InvoiceDetail} isPrivate />
    </Switch>
  );
}
