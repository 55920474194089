import { createGlobalStyle, css, keyframes } from 'styled-components';
import { darken } from 'polished';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

const rotate = keyframes` 
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg);
  }
`;
export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
*{
margin: 0;
padding: 0;
outline: 0;
box-sizing: border-box;
}
*:focus{
  outline: 0;
}

html,body, #root{
   height : 100%;
}

body{
  background: #fff ;
  -webkit-font-smoothing: antialiased;
}

body, input, button{
  font: 14px 'Roboto',sans-serif;
}
a{
  text-decoration: none;
}

ul{
  list-style: none;
}

button {
  cursor: pointer;
}
form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #fff;
      margin: 0 0 10px;

      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    span {
      color: #ffcb08;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    button#default{
      display: flex;
      align-items: center;
      padding: 5px;
      justify-content: center;
      height: 44px;
      background: #f47920;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background(0.2s);

      &:hover {
        background: ${darken(0.03, '#f47920')};
      }

      ${props =>
        props.loading &&
        css`
          svg {
            animation: ${rotate} 2s linear infinite;
          }
        `}
    }

    button#danger {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0 0;
      height: 44px;
      background: #dc3545;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background(0.2s);
      p {
        margin-left: 5px;
      }

      &:hover {
        background: ${darken(0.08, '#dc3545')};
      }
    }

    label {
      display: flex;
      align-items: left;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
      margin: 0 0 5px;
    }


  }
  span#alert {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #f47920;
  }
`;
