import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import {
  signInSuccess,
  signInFailure,
  signUpSuccess,
  signUpFailure,
} from './actions';

export function* signIn({ payload }) {
  try {
    const { cpf, password } = payload;

    const response = yield call(api.post, 'sessions', {
      cpf,
      password,
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Barear ${token}`;

    yield put(signInSuccess(token, user));

    history.push('/dashboard');
  } catch (error) {
    toast.error(error.response.data.message);
    yield put(signInFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const data = {
      ...payload.data,
      type_user_id: 1,
    };

    yield call(api.post, 'beneficiaries', data);

    toast.success('Usuário cadastrado com sucesso!');

    yield put(signUpSuccess());

    setTimeout(() => {
      history.push('/');
    }, 3000);
  } catch (error) {
    toast.error('Erro ao cadastrar usuário, confira seus dados!');
    yield put(signUpFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Barear ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
