import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const Context = createContext();

const Provider = ({ children }) => {
  const getBilletToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');

    return urlToken;
  };

  return (
    <Context.Provider value={{ getBilletToken }}>{children}</Context.Provider>
  );
};

function useProviderHook() {
  const context = useContext(Context);

  return context;
}

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export { Provider, useProviderHook };
