import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { MdPrint } from 'react-icons/md';

import { formatter } from '~/utils/formatCurrency';

import { Container, ListService, Service, Top } from './styles';

export default function InvoiceDetailing({ historics, handleGeneratePdf }) {
  const hasHistorics = useMemo(() => historics.length > 0, [historics]);

  return (
    <Container>
      {hasHistorics && (
        <div>
          <button type="button" onClick={handleGeneratePdf}>
            Gerar PDF <MdPrint size={20} color="#fff" />
          </button>
          Valor Total: {historics[0].totalFormatted}
        </div>
      )}
      <ListService>
        <Top>
          <div>Local de Atendimento/Itens</div>
          <div>Data</div>
        </Top>
        {hasHistorics
          ? historics.map(historic => (
              <Service key={historic.ATENDIMENTO}>
                <div>
                  <strong>{historic.PRESTADOR}</strong>
                  <span>{historic.DATA}</span>
                </div>
                {historic.services.map(service => (
                  <div id="service" key={service.ID}>
                    <strong>
                      {service.ITEM} - {formatter(service.VALOR)}
                    </strong>
                    <strong>{service.TIPO}</strong>
                  </div>
                ))}
              </Service>
            ))
          : 'Nenhum atendimento encontrado para o filtro informado'}
      </ListService>
    </Container>
  );
}

InvoiceDetailing.propTypes = {
  historics: PropTypes.arrayOf(
    PropTypes.shape({
      totalFormatted: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleGeneratePdf: PropTypes.func.isRequired,
};
