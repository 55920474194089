import React from 'react';
import PropTypes from 'prop-types';

import { Container, Content, Scroll } from './styles';

const DefaultModal = ({ children, size }) => (
  <Container>
    <Scroll>
      <Content size={size}>{children}</Content>
    </Scroll>
  </Container>
);

DefaultModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  size: PropTypes.string,
};

DefaultModal.defaultProps = {
  size: 'default',
};

export default DefaultModal;
