import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .css-2b097c-container {
    > div {
      color: #333;
      margin: 10px 0 10px;
      box-shadow: 0 0 0 1px hsl(0, 0%, 80%);
      border-color: hsl(0, 0%, 80%);
    }
    input {
      height: 15px;
    }
  }
`;
