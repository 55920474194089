import React, { useRef, useEffect } from 'react';
import { useField } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import InputSwitch from 'react-switch';

import { Container } from './styles';

export default function Switch({ onChange, checked, name, ...rest }) {
  const switchRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: switchRef.current,
      path: 'value',
    });
  }, [fieldName, switchRef.current]); //eslint-disable-line

  const props = {
    ...rest,
    ref: switchRef,
    id: fieldName,
    name: fieldName,
    'aria-label': fieldName,
    defaultValue,
  };

  return (
    <Container>
      <label>
        <label htmlFor={fieldName}>Desejo receber boleto por email</label>
        <InputSwitch
          onChange={onChange}
          checked={checked}
          name={fieldName}
          onColor="#00995d"
          offColor="#dc3545"
          {...props}
        />
      </label>
      {error && <span>{error}</span>}
    </Container>
  );
}

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};
