import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Form } from '@rocketseat/unform';
import { FaSpinner } from 'react-icons/fa';
import logo from '~/assets/images/logo.png';
import api from '~/services/api';
import history from '~/services/history';

import Input from '~/components/Input';

import { Container } from './styles';

const schema = Yup.object().shape({
  cpf: Yup.string().required('O CPF é obrigatório'),
  email: Yup.string()
    .email('Informe um email válido')
    .required('O email é obrigatório'),
});

export default function RecoveryPassword() {
  const [loading, setLoading] = useState(false);
  async function handleSubmit(data) {
    const cpf = data.cpf.replace(/[^0-9]/g, '');

    const recovery = {
      ...data,
      cpf,
    };
    try {
      setLoading(true);
      await api.post('/beneficiaries/recovery', recovery);
      toast.success(
        `Foi enviado um e-mail para ${recovery.email}, verifique-o e siga as orientações!`
      );
      setTimeout(() => {
        history.push('/');
      }, 3000);
      setLoading(false);
    } catch (error) {
      toast.error('Dados inválidos, verifique e tente novamente');
      setLoading(false);
    }
  }
  return (
    <Container loading={loading}>
      <img src={logo} alt="Agenda Unimed" />
      <Form onSubmit={handleSubmit} schema={schema}>
        <Input
          name="cpf"
          type="text"
          label="CPF"
          mask="999.999.999-99"
          placeholder="Informe seu cpf para recuperar a senha"
        />
        <Input
          name="email"
          type="email"
          label="E-mail"
          placeholder="Informe seu email para recuperar a senha"
        />

        <button id="default" type="submit">
          {loading ? <FaSpinner size={24} color="#fff" /> : 'Recuperar Senha'}
        </button>
        <Link to="/">Voltar para o login</Link>
      </Form>
    </Container>
  );
}
