import styled, { keyframes, css } from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { darken } from 'polished';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg);
  }
`;
export const Container = styled.div`
  z-index: 5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Content = styled.div`
  background: ${props => (props.background ? props.background : '#fff')};
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  width: ${props => (props.size === 'big' ? 600 : 400)}px;
  @media (max-width: 430px) {
    width: 250px;
  }

  > header {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    div#title {
      display: flex;
      justify-content: center;
      h1 {
        font-size: 22px;
        color: #f47920;
        margin: 15px;
      }
      @media (max-width: 430px) {
        h1 {
          font-size: 16px;
        }
      }
    }
    > span {
      padding-left: 5px;
      color: #00995d;
      font-weight: bold;
    }
    div#close {
      display: flex;
      justify-content: flex-end;
      > button {
        background: none;
        border: none;
        color: #dc3545;
        font-size: 18px;
        padding: 10px;
        &:hover {
          background: darken(0.03, '#dc3545');
        }
      }
    }
  }

  > content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0 10px 10px;
    > h1 {
      font-size: 22px;
      color: #f47920;
      margin: 15px;
    }
    > span {
      font-size: 18px;
    }
    > ul {
      display: flex;
      flex-direction: column;
      width: 550px;
      > li {
        display: flex;
        align-items: center;
        background: #00995d;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        justify-content: space-between;
        > p {
          margin-left: 5px;
        }
        > button {
          font-weight: bold;
          color: #fff;
          border: 0;
          background: #f47920;
          padding: 5px;
          margin-left: 10px;
          border-radius: 5px;
          &:hover {
            background: ${darken(0.03, '#f47920')};
          }
        }
      }
    }
  }
  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    > button {
      margin: 5px 5px;
      padding: 5px;
      height: 44px;
      background: #dc3545;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background(0.2s);

      &:hover {
        background: ${darken(0.03, '#dc3545')};
      }

      ${props =>
        props.loading &&
        css`
          svg {
            animation: ${rotate} 2s linear infinite;
          }
        `}
    }
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 30px 15px 0 15px;

    input,
    textarea {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      padding: 0 5px;
      height: 44px;
      color: #333;
      margin: 0 0 10px;

      &::placeholder {
        color: #333;
      }
    }

    textarea {
      max-width: 570px;
      max-height: 100px;
    }

    hr {
      border: 0;
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      margin: 10px 0 10px;
    }

    span {
      color: #dc3545;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    div#button {
      display: flex;
      margin-bottom: 10px;
    }

    button#delete {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      height: 44px;
      background: #dc3545;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background(0.2s);
      p {
        margin-left: 5px;
      }

      &:hover {
        background: ${darken(0.08, '#dc3545')};
      }

      ${props =>
        props.loading &&
        css`
          svg {
            animation: ${rotate} 2s linear infinite;
          }
        `}
    }

    label {
      color: #f47920;
      font-weight: bold;
      font-size: 16px;
      margin: 0 0 5px;
    }

    select {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #333;
      margin: 0 0 10px;
    }
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height: 600px;
`;
