import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { updateProfileSuccess, updateProfileFailure } from './actions';

import api from '~/services/api';

export function* updateProfile({ payload }) {
  try {
    const { id, email, avatar_id, send_billet, ...rest } = payload.data;

    const profile = {
      id,
      email,
      avatar_id,
      send_billet,
      ...(rest.oldPassword ? rest : {}),
    };

    const response = yield call(
      api.put,
      `beneficiaries/${profile.id}`,
      profile
    );

    toast.success('Perfil atualizado com sucesso');

    yield put(updateProfileSuccess(response.data));
  } catch (error) {
    toast.error('Erro ao atualizar perfil, confira seus dados!');
    yield put(updateProfileFailure());
  }
}

export default all([
  takeLatest('@beneficiarie/UPDATE_PROFILE_REQUEST', updateProfile),
]);
