import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import { confirmAlert } from 'react-confirm-alert';

import { FaSpinner } from 'react-icons/fa';

import { updateProfileRequest } from '~/store/modules/beneficiarie/actions';
import { signOut } from '~/store/modules/auth/actions';

import AvatarInput from './AvatarInput';

import InputSwitch from '~/components/Switch';

import { Container } from './styles';

export default function Profile() {
  const dispatch = useDispatch();
  const { profile, loading } = useSelector(state => state.beneficiarie);
  const [checked, setChecked] = useState(profile.send_billet);

  // useEffect(() => {
  //   setChecked(profile.send_billet);
  // }, [profile.send_billet, setChecked]);

  function handleSubmit(data) {
    delete data.send_billet;
    const dt = {
      ...data,
      id: profile.id,
      send_billet: !!checked,
    };
    dispatch(updateProfileRequest(dt));
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  const handleChange = useCallback(() => {
    setChecked(!checked);
    const check = !checked;
    if (check)
      confirmAlert({
        title: 'Informação',
        message:
          'Ao selecionar essa opção você receberá mensalmente seu boleto por email. Caso não esteja recebendo o email, verifique se foi preenchido corretamente ou se o email não chegou em sua lixeira ou caixa de spam!',
        buttons: [
          {
            label: 'Ok',
          },
        ],
      });
  }, [checked]);
  return (
    <Container loading={loading}>
      <Form initialData={profile} onSubmit={handleSubmit}>
        <AvatarInput name="avatar_id" name_user={profile.name} />
        <InputSwitch
          onChange={handleChange}
          checked={!!checked}
          name="send_billet"
        />
        {/* <div id="checkbox">
          <Input
            name="send_billet"
            type="checkbox"
            label="Quero receber boleto por email"
            onChange={handleChange}
            checked={!!checked}
          />
        </div> */}
        <Input name="email" type="email" placeholder="Seu endereço de email" />
        <hr />
        <Input
          type="password"
          name="oldPassword"
          placeholder="Sua senha atual"
        />
        <Input type="password" name="password" placeholder="Nova senha" />
        <Input
          type="password"
          name="confirmPassword"
          placeholder="Confirmação de senha"
        />
        <button type="submit">
          {loading ? <FaSpinner size={24} color="#fff" /> : 'Atualizar perfil'}
        </button>
        <button id="cancel" type="button" onClick={handleSignOut}>
          Sair do sistema
        </button>
      </Form>
    </Container>
  );
}
