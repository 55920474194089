import styled, { keyframes, css } from 'styled-components';

import { darken } from 'polished';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 30px 0 30px;
  div#animation {
    display: flex;
    align-items: center;
    justify-content: center;
    ${props =>
      props.loading &&
      css`
        svg {
          animation: ${rotate} 2s linear infinite;
        }
      `}
  }
`;

export const Dates = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (max-width: 500px) {
      flex-direction: column;
    }
  }

  margin-top: 30px;

  label {
    color: #f47920;
  }

  input {
    display: flex;
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #333;
    margin: 0 10px 10px;

    &::placeholder {
      color: #333;
    }
  }

  hr {
    border: 0;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 10px 0 20px;
  }

  span {
    color: #ffcb08;
    align-self: flex-start;
    margin: 0 10px 10px;
    font-weight: bold;
  }

  button#submit {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
      margin: 15px 0 0;
      width: 100%;
    }

    height: 40px;
    padding: 10px;
    background: #f47920;
    font-weight: bold;
    border: 0;
    border-radius: 8px;
    font-size: 16px;
    transition: background(0.2s);

    ${props =>
      props.loading &&
      css`
        svg {
          animation: ${rotate} 2s linear infinite;
        }
      `}

    &:hover {
      background: ${darken(0.08, '#f47920')};
    }
  }
`;
