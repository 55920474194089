import React from 'react';
import PropTypes from 'prop-types';

import { ListImage, BannerUnimed, Container } from './styles';

export default function Banner({ banners }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    adaptiveHeight: true,
    autoplay: true,
  };
  return (
    <Container>
      <BannerUnimed {...settings}>
        {banners.map(banner => (
          <ListImage key={banner.id}>
            <a
              target="blank"
              href={`http://www.unimedtreslagoas.com.br/modulos/artigos/index.php?post=${banner.id}`}
            >
              <img src={banner.urlCapaSlide} alt={banner.titulo} />
            </a>
          </ListImage>
        ))}
      </BannerUnimed>
    </Container>
  );
}

Banner.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      urlCapaSlide: PropTypes.string.isRequired,
      titulo: PropTypes.string.isRequired,
    })
  ).isRequired,
};
