import styled, { css, keyframes } from 'styled-components';
import { lighten } from 'polished';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  margin: 50px 30px 0 30px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 30px 0 30px;
`;
export const Title = styled.div`
  text-align: center;
  font-size: 32px;
  text-decoration: underline;
  color: #f47920;
  font-weight: bold;
  margin-bottom: 100px;
`;
export const Billets = styled.div`
  div#animation {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${props =>
    props.loading &&
    css`
      svg {
        animation: ${rotate} 2s linear infinite;
      }
    `}
`;
export const BilletContent = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #999;
  padding-bottom: 15px;
  strong {
    font-size: 18px;
    color: #00995d;
  }
  button {
    color: #fff;
    background: #f47920;
    border: 0;
    padding: 5px;
    border-radius: 4px;
  }

  & + & {
    margin-top: 15px;
  }
`;

export const BilletView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  div#frame {
    margin-top: 15px;
    > iframe {
      width: 800px;
      height: 600px;
    }
  }
  @media (max-width: 825px) {
    div#frame {
      > iframe {
        display: none;
      }
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #00995d;
  border-radius: 4px;
  color: #fff;
  height: 56px;
  padding: 5px 25px;
  margin-right: 30px;
  font-size: 14px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background-color: ${lighten(0.05, '#00995d')};
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;
